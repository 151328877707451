import Image from "next/image";

import Styles from './styles.module.scss'
import React from "react";

import Background from '@/assets/img/background-menu.svg'
import DiscordIcon from '@/assets/img/DiscordIcon.svg'
import InstagramIcon from '@/assets/img/InstagramIcon.svg'
import YoutubeIcon from '@/assets/img/YoutubeIcon.svg'
import LinkedinIcon from '@/assets/img/LinkedinIcon.svg'
import GradientBar from "@/components/atoms/GradientBar/GradientBar";
import Link from "next/link";

export default function MenuMobileFooter({ div1Open }: any) {
    return (
        <div style={{ height: '100%', marginLeft: '10%' }}>
            <Image src={Background} style={div1Open ? { position: 'absolute', width: '65vh', left: -25, objectFit: 'fill', objectPosition: '90% 160%', height: '100vh', top: -75, zIndex: -1 } : { display: 'none', visibility: 'hidden', opacity: 0 }} alt="Background" />
            <div className={Styles.footer} style={{ display: 'flex', visibility: 'visible', flexDirection: 'column', height: '100%' }}>
                <GradientBar classname={Styles.alternative} />
                <p style={div1Open ? { visibility: 'visible', margin: 0, marginTop: 20 } : { display: 'none', visibility: 'hidden' }}>Acompanhe a CoinLivre:</p>
                <div className={Styles.socialMenuItems} style={{ display: 'flex', visibility: 'visible' }}>
                    <div style={div1Open ? { visibility: 'visible', width: 20 } : { display: 'none', visibility: 'hidden' }}>
                        <Link href="https://discord.com/invite/Xx9U4j74Aa" target='_blank'>
                            <Image src={DiscordIcon} alt="Icon" style={{ width: 20 }} />
                        </Link>
                    </div>
                    <div style={div1Open ? { visibility: 'visible', width: 20 } : { display: 'none', visibility: 'hidden' }}>
                        <Link href="https://www.instagram.com/coinlivre_/" target='_blank'>

                            <Image src={InstagramIcon} alt="Icon" style={{ width: 20 }} />
                        </Link>
                    </div>
                    <div style={div1Open ? { visibility: 'visible', width: 20 } : { display: 'none', visibility: 'hidden' }}>
                        <Link href="https://www.linkedin.com/company/coinlivre/" target='_blank'>
                            <Image src={LinkedinIcon} alt="Icon" style={{ width: 20 }} />
                        </Link>
                    </div>
                    <div style={div1Open ? { visibility: 'visible', width: 20 } : { display: 'none', visibility: 'hidden' }}>
                        <Link href="https://www.youtube.com/@metacast9068" target='_blank'>
                            <Image src={YoutubeIcon} alt="Icon" style={{ width: 20 }} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
};
