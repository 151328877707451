import React, { useContext } from "react";
import Styles from "./styles.module.scss";
import { useCycle } from "framer-motion";
import Overlay from "@/components/molecules/Overlay";

import logoCL from "@/assets/img/logoCL.svg";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { fetchDataUserInfo } from "@/utils/fetchDataAxios";
import HamburguerMenuMobile from "@/components/molecules/HamburguerMenuMobile/HamburguerMenuMobile";
import UserOptionsMobile from "@/components/molecules/UserOptionsMobile/UserOptionsMobile";
import WalletContext from "@/context/WalletContext";
import pt from '@/public/locales/pt/common.json';
import en from '@/public/locales/en/common.json';
import es from '@/public/locales/es/common.json';
export default function HeaderMobile({ envs }: any) {
	const { setShowWallet, setStageWallet } = useContext(WalletContext);
	const [isOpen, toggleOpen] = useCycle(false, true);
	const [menuItemIsOpen, setMenuItemIsOpen] = React.useState<boolean>(false);
	const [openOverlay, setOpenOverlay] = React.useState<boolean>(false);
	const [dataUser, setDataUser] = React.useState<any>();
	const [div1Open, setDiv1Open] = React.useState(false);
	const [div2Open, setDiv2Open] = React.useState(false);
	const [showButton, setShowButton] = React.useState(false);

	const { locale } = useRouter();
    
    function handleLanguage(locale: string) {
		switch (locale) {
			case 'en': return en
			case 'pt': return pt
			case 'es': return es
			default: return pt
		}
	}
    const t = locale ? locale : 'pt';
    const textT = handleLanguage(t)

	setTimeout(() => {
		setShowButton(true);
	}, 1000);

	const handleDiv1Click = () => {
		setMenuItemIsOpen(!menuItemIsOpen);
		setOpenOverlay(div2Open ? true : !openOverlay);
		setDiv1Open(!div1Open);
		// Fechar a outra div, caso esteja aberta
		if (div2Open) {
			setDiv2Open(false);
		}
	};

	const handleDiv2Click = () => {
		setMenuItemIsOpen(!menuItemIsOpen);
		setOpenOverlay(div1Open ? true : !openOverlay);
		setDiv2Open(!div2Open);
		// Fechar a outra div, caso esteja aberta
		if (div1Open) {
			setDiv1Open(false);
		}
	};

	const router = useRouter();

	React.useEffect(() => {
		// console.log(window.location.pathname)

		const accessToken = localStorage.getItem("accessToken");
		let logado = JSON.parse(localStorage.getItem("logado")!);

		if (!logado) {
			fetchDataUserInfo(
				accessToken,
				setDataUser,
				router,
				envs.NEXT_PUBLIC_GET_USER_INFO
			);
		} else {
			setDataUser(logado);
		}

		// router.push(`${beforePath}`)
	// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

	return showButton ? (
		<div>
			<div className={Styles.headerContainer}>
				{openOverlay && (
					<Overlay
						isOpen={openOverlay}
						setIsOpen={setOpenOverlay}
						setMenuItemIsOpen={setMenuItemIsOpen}
					/>
				)}
				<div>
					<HamburguerMenuMobile
						div1Open={div1Open}
						div2Open={div2Open}
						handleDiv1Click={handleDiv1Click}
						t={t}
						textT={textT}
						toggleOpen={toggleOpen}
					/>
				</div>

				<div style={{ marginLeft: "20px" }}>
					<Link href={"/"}>
						<Image src={logoCL} width={35} height={35} alt="Logo CoinLivre" />
					</Link>
				</div>

				{!dataUser?.name ? (
					<div className={Styles.enterButton}>
						<Link href={"/login"} style={{ textDecoration: "none" }}>
							<p>{textT.login}</p>
						</Link>
					</div>
				) : (
					<UserOptionsMobile
						name={dataUser.name}
						profileImageSrc={dataUser?.profile_pic}
						isAdmin={dataUser.administrator}
						div2Open={div2Open}
						envs={envs}
						handleDiv2Click={handleDiv2Click}
						email={dataUser.email}
					/>
				)}
			</div>

			{dataUser?.name && (
				<section id={Styles.switch}>
					<button
						type="button"
						onClick={() => {
							setShowWallet(true);
							setStageWallet(0);
						}}
					>
						{textT.Header.CoinLivreInvestments}
					</button>

					{dataUser?.type === "FUND_MANAGER" &&
					dataUser.userFundManager?.isActive ? (
						<button
							id={Styles.pools}
							type="button"
							onClick={() => setShowWallet(false)}
						>
							{textT.Header.PoolControls}
						</button>
					) : null}
				</section>
			)}
			<div className={Styles.alternative}></div>
		</div>
	) : (
		<div></div>
	);
}
