import Image from "next/image"
import BrasilIcon from '@/assets/img/emojiBrasil.svg'
import SpainIcon from '@/assets/img/SpainEmoji.svg'
import USAIcon from '@/assets/img/USAemoji.svg'
type Props = {
    language: string
    div1Open?: boolean
}

function handleIcon(language: string) {
    switch (language) {
        case 'EN': return USAIcon
        case 'PT': return BrasilIcon
        case 'ES': return SpainIcon
    }
}

export default function LanguageItemMenu({ div1Open, language }: Props) {
    return (
        <div style={!div1Open ? { display: 'flex', gap: 5 }: { display: 'block', visibility: 'hidden' }}>
            <Image src={handleIcon(language)} style={{ width: 15, height: 15, objectFit: 'cover' }} alt="Brasil Icon" />
            <p style={{ margin: 0, fontSize: 10 }}>
                {language}
            </p>
        </div>
    )
};
