

import * as React from 'react'
import Styles from './styles.module.scss'
import { motion, useCycle } from 'framer-motion'

const Path = (props: any) => (
    
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
)


export function MenuToggleMobile ({ toggle, isOpen, handleDiv1Click }: any)  {
   
    return (
        <motion.nav
         initial={false}
             animate={isOpen ? 'open' : 'closed'}
             className={Styles.container}
         >
                <button
    // className="absolute left-[28px] top-[18px] h-[50px] w-[50px] cursor-pointer select-none rounded-[50%] border-none bg-transparent outline-0"
                className={Styles.button}  >
                <svg width="23" height="23" viewBox="0 0 23 23"  onClick={() => { 
                toggle()
                handleDiv1Click()
             }}>
                  <Path
                    fill={'#FFF'}
                    stroke={'#FFF'}
                  variants={{
                  closed: { d: 'M 2 2.5 L 20 2.5' },
                      open: { d: 'M 3 16.5 L 17 2.5' },
                   }}
                    />
      <Path
         fill={'#FFF'}
         stroke={'#FFF'}
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
           fill={'#FFF'}
           stroke={'#FFF'}
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </button>

  </motion.nav>
    )
}
//     <motion.nav
//     initial={false}
//     animate={isOpen ? 'open' : 'closed'}
//     custom={height}
//     ref={containerRef}
//     className="fixed inset-y-0 h-[500px] w-[300px]"
//   >
   
//   <button
//     onClick={toggle}
//     // className="absolute left-[28px] top-[18px] h-[50px] w-[50px] cursor-pointer select-none rounded-[50%] border-none bg-transparent outline-0"
//     className={Styles.button}  >
//     <svg width="23" height="23" viewBox="0 0 23 23">
//       <Path
     
//         variants={{
//           closed: { d: 'M 2 2.5 L 20 2.5' },
//           open: { d: 'M 3 16.5 L 17 2.5' },
//         }}
//       />
//       <Path
//         d="M 2 9.423 L 20 9.423"
//         variants={{
//           closed: { opacity: 1 },
//           open: { opacity: 0 },
//         }}
//         transition={{ duration: 0.1 }}
//       />
//       <Path
 
//         variants={{
//           closed: { d: 'M 2 16.346 L 20 16.346' },
//           open: { d: 'M 3 2.5 L 17 16.346' },
//         }}
//       />
//     </svg>
//   </button>
//   </motion.nav>

