import { useEffect } from 'react'
import Section from '@/organisms/Section'
import Title from '@/atoms/Title'
import Paragrah from '@/atoms/Paragraph'
import Column from '@/molecules/Column'
import Social from '@/molecules/Social'
import Copyright from '@/molecules/Copyright'
import Image from 'next/image'

// Static
import Styles from './styles.module.scss'
import Logo from '@/assets/img/logo-white.webp'
import Metacast from '@/assets/img/metacast.webp'
import Networks from '@/molecules/Networks'

// languages
import en from '@/public/locales/en/common.json';
import pt from '@/public/locales/pt/common.json';
import es from '@/public/locales/es/common.json';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'

import SocialMedia from '@/components/molecules/SocialMedia'

import LogoIcon from '@/assets/img/logoCL.svg'

import Link from 'next/link'


const Footer = () => {
  const router = useRouter()
  const { locale } = router;

	function handleLanguage(locale: string) {
		switch (locale) {
			case "en":
				return en;
			case "pt":
				return pt;
			case "es":
				return es;
			default:
				return pt;
		}
	}
	const t = handleLanguage(locale ? locale : "pt");

  useEffect(() => {
    // const beforePath = localStorage.getItem('beforePath')
    // router.push(`${beforePath}`)
  })
  return (
    <footer style={{ width: '100%', }}>
      <Section
        id='footer'
        label='footer-title'
        desc='footer-description'
        justify='center'
        hidden={false}
        className={`${Styles.background} d-flex align-items-center py-5`}
      >
        <Title
          id='footer-title'
          text="Rodap� da p�gina"
          className='visually-hidden'
          hidden={false}
        />
        <Paragrah
          id='footer-description'
          text='Navegue pelo mapa do site e outros links �teis.'
          className='visually-hidden'
          hidden={false}
        />
        <section className={Styles.linksStyle} style={{
          // display: 'flex',
          // justifyContent: 'space-around',
          // alignItems: 'flex-start',
          // flexWrap: 'wrap'
        }}>
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: 20 }}>

            <Image
              src={LogoIcon}
              alt='Logomarca da CoinLivre'
              width={50}
              height={undefined}
            />
            <SocialMedia />
          </div>
          <div className={Styles.footer__links}>
            <h1 className={Styles.footer__subtitle}>
              {t.Footer.aboutCoinLivre}
            </h1>
            <ul className={Styles.footer__list}>
              <li className={Styles.footer__item}>
                <Link href="/#quem-somos">{t.Footer.aboutUs}</Link>
              </li>
              <li className={Styles.footer__item}>
                <Link href="/#projetos">{t.Footer.ourProjects}</Link>
              </li>
              <li className={Styles.footer__item}>
                <a href="mailto:imprensa@coinlivre.com.br">{t.Footer.release}</a>
              </li>
            </ul>
          </div>

          <div className={Styles.footer__links}>
            <h1 className={Styles.footer__subtitle}>
              {t.Footer.legal}
            </h1>
            <ul className={Styles.footer__list}>
              <li className={Styles.footer__item}>
                <a href='/TERMO_DE_USO.pdf' download>{t.Footer.terms}</a>
              </li>
              <li className={Styles.footer__item}>
                <a href='/POLITICA_DE_PRIVACIDADE.pdf' download>{t.Footer.policy}</a>
              </li>
              <li className={Styles.footer__item}>
                <a href='/PREVEN��O_LAVAGEM_DINHEIRO.pdf' download>Pol�tica de PLD e FT</a>
              </li>
            </ul>
          </div>

          <div className={Styles.footer__links}>
            <h1 className={Styles.footer__subtitle}>
              {t.Footer.help}
            </h1>
            <ul className={Styles.footer__list}>
              <li className={Styles.footer__item}>
                <a href="mailto:faleconosco@coinlivre.com.br">{t.Footer.contactUs}</a>
              </li>
              <li className={Styles.footer__item}>
                <a href="#faq">{t.Footer.faq}</a>
              </li>
              <li className={Styles.footer__item}>
                <a
                  rel='noreferrer'
                  href="https://www.youtube.com/@metacast9068"
                  target="_blank">{t.Footer.tokenization}</a>
              </li>
            </ul>
          </div>

          <div className={Styles.footer__links}>
            <h1 className={Styles.footer__subtitle}>
              {t.Footer.compliance}
            </h1>
            <ul className={Styles.footer__list}>
              <li className={Styles.footer__item}>
                <a href="mailto:faleconosco@coinlivre.com.br">{t.Footer.ouvidoria}</a>
              </li>
            </ul>
          </div>
          <div style={{ width: 1, border: '1px solid white' }} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ fontWeight: 'bold' }}>
              {t.Footer.podcast}
            </p>
            <Link
              href={'https://www.youtube.com/@metacast9068'}
              target='_blank'
              style={{ cursor: 'pointer' }}
            >

              <Image
                src={Metacast}
                alt='Logomarca da MetaCast'
                width={150}
                height={undefined}
              />
            </Link>

          </div>





        </section>
        <Copyright />
      </Section>
      <Networks />
    </footer>
  )
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['footer']))
    }
  }
}

export default Footer