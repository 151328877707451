import React from "react";
import Header from "@/components/organisms/Header";
import Footer from "@/components/organisms/Footer";
import HeaderMobile from "@/components/organisms/HeaderMobile/HeaderMobile";
import Styles from "./styles.module.scss";
import { useRouter } from "next/router";
import { fetchDataUserInfo } from "@/utils/fetchDataAxios";
import FooterMobile from "@/components/molecules/FooterMobile";

type Props = {
	id: string;
	role: string;
	label: string;
	desc?: string;
	className?: string;
	hideHeaderLinks?: boolean;
	children: any;
	envs?: any;
};

const Frame = ({
	id,
	role,
	label,
	desc,
	className,
	children,
	hideHeaderLinks = false,
	envs,
}: Props) => {
	return (
		<div
			id={id}
			role={role}
			aria-labelledby={label}
			aria-describedby={desc}
			className={className}
		>
			<div className={Styles.mobileHeader}>
				<HeaderMobile envs={envs} />
			</div>
			<div className={Styles.desktopHeader}>
				<Header envs={envs} />
			</div>
			{children}
			<div className={Styles.desktopFooter}>
				<Footer />
			</div>
			<div className={Styles.mobileFooter}>
				<FooterMobile />
			</div>
		</div>
	);
};

export default Frame;
