import { MenuToggle } from "@/components/atoms/MenuToggle/MenuToggle";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import Styles from './styles.module.scss'
import { useState, Dispatch, SetStateAction } from "react";
import { MenuToggleMobile } from "@/components/atoms/MenuToggleMobile/MenuToggleMobile";
import Image from "next/image";
import CellphoneImage from '@/assets/img/imagemCelularMenu.svg'
import GradientBar from "@/components/atoms/GradientBar/GradientBar";
import Background from '@/assets/img/background-menu.svg'
import DiscordIcon from '@/assets/img/DiscordIcon.svg'
import InstagramIcon from '@/assets/img/InstagramIcon.svg'
import YoutubeIcon from '@/assets/img/YoutubeIcon.svg'
import LinkedinIcon from '@/assets/img/LinkedinIcon.svg'
import { useRouter } from "next/router";
import LanguageItemMenu from "@/components/atoms/LanguageItemMenu/LanguageItemMenu";


interface Props {
    toggleOpen: () => void,
    handleDiv1Click: () => void,
    div1Open: boolean
    div2Open: boolean
    t:any,
    textT:any
}
type ILanguage = {
    language: string
  }[]

export default function HamburguerMenuMobile({ toggleOpen, handleDiv1Click, div1Open, div2Open, t, textT }: Props) {
    const router = useRouter();
    const [openLanguageMenu, setOpenLanguageMenu] = useState<boolean>(false)
    const [language, setLanguage] = useState(t)
    const languages: ILanguage = [
        {
          language: 'PT'
        },
        {
          language: 'EN'
        },
        {
          language: 'ES'
        }
      ]

      function handleChangeLanguage(language: string) {
        router.push(router.pathname, router.asPath, { locale: language.toLowerCase() });
      }
    const routes: Array<any> = [
        { name: textT.Header.CoinLivre.Title, path: "/#quem-somos", disabled: false },
        { name: textT.Header.MeetProjects.Title, path: "/#projetos", disabled: true },
        { name: textT.Header.Blog.Title, path: "#", disabled: true },
        { name: textT.Header.TalkWithUs, path: "mailto:contato@blocklize.tech", disabled: true },
    ]

    return (
        <div>
            <div style={div2Open ? { opacity: 0 } : { opacity: 1 }}>

                <MenuToggleMobile toggle={() => toggleOpen()} isOpen={div1Open} handleDiv1Click={handleDiv1Click} />
            </div>
            <motion.div className={Styles.alternative2} animate={div1Open ? {
                opacity: 1,
                height: '100vh',
                width: '100%',
                pointerEvents: 'none',
            } :
                { opacity: 0, height: 0 }}
                style={{ position: 'absolute', pointerEvents: 'none', marginTop: 40 }}
                transition={{ type: 'inertia' }}
            >
                {routes.map((item, i) => (
                    <motion.div key={i} style={div1Open ? { fontSize: 40, pointerEvents: 'all', cursor: 'pointer' } : { pointerEvents: 'none' }}
                        animate={div1Open ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }}
                        transition={{ duration: 0.3, bounce: true }} className={Styles.menuItem}
                        onClick={() => { handleDiv1Click() }}
                    >
                        {/* <Link className={styles.menuItems__itemMenu} key={`${item} - ${i}`} locale={locale} href={item.path}>{item.name}</Link> */}
                        <a href={item.path} style={{ textDecoration: 'none' }} onClick={() => { item.path === '/#projetos' ? handleDiv1Click() : null }}>
                            <p style={div1Open ? { color: 'white', margin: 0 } : { color: 'black', margin: 0 }}>
                                {item.name}
                            </p>
                        </a>


                    </motion.div>
                ))}
                <div style={div1Open ? { height: 100, display: 'flex', visibility: 'visible' } : { height: 0, display: 'block', visibility: 'hidden', zIndex: -200 }}>
                 
                    <AnimatePresence>
                        <motion.div style={div1Open ? { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 5, marginLeft: 10 } : { display: 'block', visibility: 'hidden' }} onClick={(() => setOpenLanguageMenu(!openLanguageMenu))} initial={{ height: 20, border: '1px solid white', width: 55, borderRadius: 4 }} animate={openLanguageMenu ? { height: 60 } : {}} exit={{ height: 20, width: 55, borderRadius: 4 }}>
                            <motion.div style={{ pointerEvents: 'all', cursor: 'pointer' }} animate={!openLanguageMenu ? { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 5, visibility: 'visible' } : { display: 'none', visibility: 'hidden' }}>
                                <LanguageItemMenu div1Open={!div1Open} language={language} />
                            </motion.div>
                            {languages.map((language) => (
                                <motion.div style={{ pointerEvents: 'all', cursor: 'pointer' }} key={language.language} onClick={(() => { setLanguage(language.language); handleChangeLanguage(language.language) })} animate={openLanguageMenu ? { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 5, visibility: 'visible' } : { display: 'none', visibility: 'hidden' }}>
                                    <LanguageItemMenu div1Open={!div1Open} language={language.language} key={language.language} />
                                </motion.div>
                            ))}
                        </motion.div>
                    </AnimatePresence>
                </div>


                <Image src={CellphoneImage} style={div1Open ? { width: '130%', height: '37%', objectFit: 'cover', position: 'relative', left: -40, marginTop: '10%' } : { display: 'none', visibility: 'hidden', opacity: 0 }} alt="Imagem menu mexendo no celular" />
                {div1Open ?
                    <GradientBar classname={Styles.alternative} /> : ''
                }
                <Image src={Background} style={div1Open ? { position: 'absolute', width: '100%', left: 0, height: '25%', objectFit: 'cover', objectPosition: '40% 70%' } : { display: 'none', visibility: 'hidden', opacity: 0 }} alt="Background" />

                <div className={Styles.footer} style={{ display: 'flex', visibility: 'visible', flexDirection: 'column' }}>
                    <p style={div1Open ? { visibility: 'visible', margin: 0, marginTop: 20 } : { display: 'none', visibility: 'hidden' }}>Acompanhe a CoinLivre:</p>
                    <div className={Styles.socialMenuItems} style={{ display: 'flex', visibility: 'visible' }}>
                        <div style={div1Open ? { visibility: 'visible', width: 20 } : { display: 'none', visibility: 'hidden' }}>
                            <a href="https://discord.com/invite/Xx9U4j74Aa">
                                <Image src={DiscordIcon} alt="Icon" style={{ width: 20 }} />
                            </a>
                        </div>
                        <div style={div1Open ? { visibility: 'visible', width: 20 } : { display: 'none', visibility: 'hidden' }}>
                            <a href="https://www.instagram.com/coinlivre_/"></a>
                            <Image src={InstagramIcon} alt="Icon" style={{ width: 20 }} />
                        </div>
                        <div style={div1Open ? { visibility: 'visible', width: 20 } : { display: 'none', visibility: 'hidden' }}>
                            <a href="https://www.linkedin.com/company/coinlivre/">
                                <Image src={LinkedinIcon} alt="Icon" style={{ width: 20 }} />
                            </a>
                        </div>
                        <div style={div1Open ? { visibility: 'visible', width: 20 } : { display: 'none', visibility: 'hidden' }}>
                            <a href="https://www.youtube.com/@metacast9068">
                                <Image src={YoutubeIcon} alt="Icon" style={{ width: 20 }} />
                            </a>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
};
