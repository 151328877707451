import DiscordIcon from '@/assets/img/DiscordIcon.svg'
import InstagramIcon from '@/assets/img/InstagramIcon.svg'
import YoutubeIcon from '@/assets/img/YoutubeIcon.svg'
import LinkedinIcon from '@/assets/img/LinkedinIcon.svg'
import Styles from './styles.module.scss'
import Image from 'next/image'
import Link from 'next/link'

export default function SocialMedia() {
    return (
        <div className={Styles.socialMenuItems} style={{ display: 'flex', visibility: 'visible' }}>
            <div>
                <a href="https://discord.gg/EkS5Z5uErC" target='_blank' rel="noreferrer">
                    <Image src={DiscordIcon} alt="Icon" style={{ width: 20 }} />
                </a>
            </div>
            <div>
                <a href="https://www.instagram.com/coinlivre_/" target='_blank' rel="noreferrer">

                    <Image src={InstagramIcon} alt="Icon" style={{ width: 20 }} />
                </a>
            </div>
            <div>
                <a href="https://www.linkedin.com/company/coinlivre/" target='_blank' rel="noreferrer">
                    <Image src={LinkedinIcon} alt="Icon" style={{ width: 20 }} />
                </a>
            </div>
            <div>
                <a href="https://www.youtube.com/@metacast9068" target='_blank' rel="noreferrer">
                    <Image src={YoutubeIcon} alt="Icon" style={{ width: 20 }} />
                </a>
            </div>
        </div>
    )
};
