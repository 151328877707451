import React from 'react'
import Section from '@/organisms/Section'
import Title from '@/atoms/Title'
import Paragrah from '@/atoms/Paragraph'
import Column from '@/molecules/Column'
import Social from '@/molecules/Social'
import Copyright from '@/molecules/Copyright'
import Image from 'next/image'

// Static
import Styles from './styles.module.scss'
import Logo from '@/assets/img/logo-white.webp'
import Metacast from '@/assets/img/metacast.webp'
import Networks from '@/molecules/Networks'

// languages
import en from '@/public/locales/en/common.json';
import pt from '@/public/locales/pt/common.json';

import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import SocialMedia from '@/components/molecules/SocialMedia'

import LogoIcon from '@/assets/img/logoCL.svg'
import Link from 'next/link'

const FooterMobile = () => {
  const router = useRouter()
  const { locale } = router;

  const t = locale === 'en' ? en : pt

  React.useEffect(() => {
    // const beforePath = localStorage.getItem('beforePath')
    // router.push(`${beforePath}`)
  })
  return (
    <footer style={{width: '100%'}}>
      <Section
        id='footer'
        label='footer-title'
        desc='footer-description'
        justify='center'
        hidden={false}
        className={`${Styles.background} d-flex align-items-center`}
      >
        <Title
          id='footer-title'
          text="Rodap� da p�gina"
          className='visually-hidden'
          hidden={false}
        />
        <Paragrah
          id='footer-description'
          text='Navegue pelo mapa do site e outros links �teis.'
          className='visually-hidden'
          hidden={false}
        />
        <div className={Styles.header}>

          <Image
            src={LogoIcon}
            alt='Logomarca da CoinLivre'
            width={50}
            height={undefined}
          />
          <div style={{ display: 'flex', alignItems: 'center', gap: 20,  }}>

            <SocialMedia />
          </div>

        </div>
        <section className={Styles.linksStyle} style={{
          // display: 'flex',
          // justifyContent: 'space-around',
          // alignItems: 'flex-start',
          // flexWrap: 'wrap'
        }}>

          <div className={Styles.footer__links}>
            <h1 className={Styles.footer__subtitle}>
              {t.aboutCoinLivre}
            </h1>
            <ul className={Styles.footer__list}>
              <li className={Styles.footer__item}>
                <Link href="/#quem-somos">{t.aboutUs}</Link>
              </li>
              <li className={Styles.footer__item}>
                <Link href="/#projetos">{t.ourProjects}</Link>
              </li>
              <li className={Styles.footer__item}>
                <a href="mailto:imprensa@coinlivre.com.br">{t.release}</a>
              </li>
            </ul>
          </div>

          <div className={Styles.footer__links}>
            <h1 className={Styles.footer__subtitle}>
              {t.legal}
            </h1>
            <ul className={Styles.footer__list}>
              <li className={Styles.footer__item}>
                <a href='/TERMO_DE_USO.pdf' download>{t.terms}</a>
              </li>
              <li className={Styles.footer__item}>
                <a href='/POLITICA_DE_PRIVACIDADE.pdf' download>{t.policy}</a>
              </li>
              <li className={Styles.footer__item}>
                <a href='/PREVEN��O_LAVAGEM_DINHEIRO.pdf' download>Pol�tica de PLD e FT</a>
              </li>
             
            </ul>
          </div>

          <div className={Styles.footer__links}>
            <h1 className={Styles.footer__subtitle}>
              {t.help}
            </h1>
            <ul className={Styles.footer__list}>
              <li className={Styles.footer__item}>
                <a href="mailto:faleconosco@coinlivre.com.br">{t.contactUs}</a>
              </li>
              <li className={Styles.footer__item}>
                <a href="#faq">{t.faq}</a>
              </li>
              <li className={Styles.footer__item}>
                <a
                  rel='noreferrer'
                  href="https://www.youtube.com/@metacast9068"
                  target="_blank">{t.tokenization}</a>
              </li>
            </ul>
          </div>

          <div className={Styles.footer__links}>
            <h1 className={Styles.footer__subtitle}>
              {t.compliance}
            </h1>
            <ul className={Styles.footer__list}>
              <li className={Styles.footer__item}>
                <a href="mailto:faleconosco@coinlivre.com.br">{t.ouvidoria}</a>
              </li>
            </ul>
          </div>
       





        </section>
        <Copyright />
      </Section>
      <Networks />
    </footer>
  )
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['footer']))
    }
  }
}

export default FooterMobile