import Styles from './styles.module.scss' 


type Props = {
    classname?: any
}
export default function GradientBar({ classname }: Props) {
    return (
        <div className={classname ? classname : Styles.alternative}  />
    )
};
